// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'page_title'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'deleted_at'
  }]
}
